.map-section {
  &.head-section{
    //margin-top: 154px;
    @include media-breakpoint-up(md){
      padding-top: 130px;
    }
    @include media-breakpoint-up(lg){
      padding-top: 230px;
    }
  }
  .overlay{
    position: relative;
  }
  .repos{
    position: absolute;
    top:0px;
  }
  .map-container{
    height: 383px;
  
    @include media-breakpoint-up(md) {
      min-height: 58vw;
    }
    @include media-breakpoint-up(lg){
      height: 30vw;
      min-height: 34rem;
    }
  }

  a:hover{
    text-decoration:none;
  }

  .underline-link{
    width: fit-content;
    margin-bottom: 5px;
    font-weight: 300;
    font-size: 1.3rem;
  }
  .underline-link:hover{
    border-bottom: 0.3px #ccc solid;
    opacity: 0.6;
  }
  .no-y-gutter{
    margin-top: 0;
    margin-bottom:0;
    padding-top: 0;
    padding-bottom: 0;
  }

  .contact-content{
    padding-bottom: 80px;
    position: relative;
    .linked-logo{
      position: absolute;
      top: 41px;
      right: 17px;
      a{
        color: #AD9379;
        img{
          padding-right: 15px;
          width: 50px;
        }
      }
    }
    
    @include media-breakpoint-up(md) {
      .linked-logo{
        top: 131px;
        right: 52px;
      }
    }
    @include media-breakpoint-up(lg) {
    //  padding-left: 19rem;
    }
  }
}

.no-padding{
  padding-left: 0px;
  padding-right: 0px;
}
