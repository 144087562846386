footer
{
  color: $white;
  background-size: contain;

  .footer-content{
    padding-top: 59px;

  }

  p {

    font-size: 1rem;
  }

  img {
    margin-top: 35px;
    height: 60px;
  }
  .overlay{
   background-color: rgba(0, 0, 0, 0.25);
    position: absolute;
    width: 100%;
    height: 100%;
    
  }
  .title-footer{
    width: fit-content;
    padding-bottom: 0.9em;
    @include media-breakpoint-up(md){
      padding-bottom: 1.6em;
    }

 
    h6{
      border-bottom: 0.08px solid #ffffff;
      padding-bottom: 7px;
      font-weight: 300;
    }
    a{
      border-bottom: 0.08px solid #ffffff;
      padding-bottom: 7px;
      font-weight: 300;
    }

  }


  a{
    color: $white;
  }
  a:hover{
    opacity: 0.6 !important;
    color: white;
    text-decoration: none;
  }
  .underline{
    border-bottom: 1px solid  #ffffff;
  }

  .underline:hover{
    text-decoration: none;
  }
  .underline-link{
    width: fit-content;
    margin-bottom: 5px;
  }
  .underline-link:hover{
    border-bottom: 0.3px #fff solid;
    opacity: 0.6;
  }
  .address {
    padding-top: 6rem;
    opacity: 0.6;
  }

  .no-padding{

    @include media-breakpoint-up(md) {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .registration{
    p{
      font-size: 0.7rem;
      padding-bottom: 0;
      margin-bottom: 5px;
    }
  }
  .connect-column{
    padding-left: 50px;
  }

  .back-button{
    position: absolute;
    right: 10rem;
  }
  
}
