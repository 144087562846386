@import 'bootstrap/variables';
@import 'bootstrap/bootstrap';

// Vendor
@import '../../node_modules/animate.css/animate';
@import '../../node_modules/flickity/css/flickity';

// Utilities
@import 'utilities/css-grid';

//elements
@import 'elements/burger';

// Components
@import 'components/header';
// @import 'components/hero';
@import 'components/logo';
@import 'components/nav';
@import 'components/image-banner';
//@import 'components/text-banner';
@import 'components/map';
@import 'components/horizontal-scroll-gallery';
@import 'components/grid-cells';
@import 'components/footer';
@import 'components/two-columns';
@import 'components/image-banner-text';
@import 'components/our-services';
@import 'components/our-team';
@import 'components/case-studies';
@import 'components/image-banner-var';
@import 'components/tabs';
@import 'components/tabs-media';
@import 'components/publications';
@import 'components/fullbio';

html {
	scroll-behavior: smooth;
}

@media screen and (prefers-reduced-motion: reduce) {
	html {
		scroll-behavior: auto;
	}
}
body {
  font-smooth: always;
  font-family: "Roboto";
  font-weight: 300;
}
p{
  padding-bottom: 15px;
  //color: #212F43;
  font-size: 1.3rem;
  line-height: 1.4;
}
h2{
  font-size: 2rem;
}
.team-page{
  background-color: #F9F9F9;
}
.model-page{
  background-color: #f6f6f6;
}
.media-page{
  background-color: #ffffff;
}
.land-page{
  background-color: rgba(33, 45, 65,0.8);
  //opacity: 0.2;
}
.case-studies-page{
  background-color: #ffffff;
}
.contact-page{
  background-color: #ffffff;
}
.services-page{
  background-color: #ECE7E2;
}

.head-section{
  padding-top:40px;
  padding-bottom:40px;
  margin-bottom:0px !important;
  @include media-breakpoint-up(md){
    padding-top:35px;
    padding-bottom:38px;
  }
  @include media-breakpoint-up(lg){
    padding-top:130px;
    padding-bottom:70px;
    margin-bottom:0px !important;
  }
}
//@import 'timeline.css';
