.case-studies{
  
  .case-studies-selector{
    a{
      background-color: unset !important;
      color: #cccccc;
      border-top: 1px solid rgba(0,0,0,0.03);
    }
    .active{
      color: #000000;
      border-top: 1px solid rgba(0,0,0,1);
    }
  }
  
  .tab-pane.active {
    animation: slide-down 3s ease-out;
  }
}
