.our-services{
  background-color: #ECE7E2;

  .variabelMargin{
    @include media-breakpoint-up(md){
      max-width: 720px;
    }
    @include media-breakpoint-up(lg){
      max-width: 960px;
    }
    @include media-breakpoint-up(xl){
      max-width: 1140px
    }

  }

  // .services-content{
  //   background-color: #ECE7E2;
  //   padding-bottom: 4rem;
  // }
  .first-element-services{
    padding-bottom: 12em;
  }
  .desktop-service-content{
    padding-bottom: 3em;
    p{
      font-size: 1rem;
      @include media-breakpoint-up(md){
        font-size: 1.3rem;
      }
    }
    p:last-child {
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }

  .step-container{
    p:not(:first-child){
      font-size: 1rem;
      @include media-breakpoint-up(md){
        font-size: 1.3rem;
      }
    }
  }

  .active-step{
    background-color: #e8e0db;
  }

  p{
    font-family: Roboto;
    font-style: normal;
  }
  .fw-400{
    font-weight: 400;
  }

  .highlight{
    font-style: italic;
    //font-weight: 400;
  }

  .circle{
    border: 2px solid;
    border-radius: 50%;
    width: 75px;
    height: 75px;
    position: relative;
    background: #ECE7E2;
    
  }
  .circle-opacity{
    border: 2px solid rgba(0,0,0,0.5);
    border-radius: 50%;
    width: 75px;
    height: 75px;
    position: relative;
    background: #ECE7E2;
  }
  img{
    width: 40px;
    left: 23%;
    top: 23%;
    position: absolute;
  }
  .vline {
    border-left: 2px dotted grey;
    height: 100%;
    position: absolute;
    left: 52.2px;
    margin-top: 11%;


  }
  .first-element{
    margin-top: 26px;
  }
  .position{
    top: 15%;
    position: absolute;
    left: 38%;
    font-size: 2em;

  }

  .no-gutter{
    margin-left: 0;
    margin-right: 0;
  }
  no-padding{
    padding-left: 0;
    padding-right: 0;
  }

}