.cell {
  padding: 3rem;
  box-sizing: border-box;
  background-size: cover;
  min-height: 22.023589285714vw;
  font-size: 1.35rem;
  font-weight: 300;
}

.cell-2x-padding {
  padding: 5rem;
}

.grid-3x5 {
  .cell {
    min-height: 20vw;
  }
}
