.tab-media{
  overflow: hidden;

  .no-padding{
    padding: 0px;
  }

  .clear{
    clear: both;
  }
  .publication-images-container{
    @include media-breakpoint-up(md){
      padding-top: 15px;
    }
  }
  .media-introduction{
    margin-bottom: 0;
    @include media-breakpoint-up(md){
      margin-bottom: 15px;
    }
  }
  h1{
    font-size: 3rem;
    font-weight: 200;
  }

  h2{
    font-weight: 200;
  }

  .article-content{
    p{
      font-size: 1rem;
      font-weight: 300;
    }
  }

  .nav-item{
    a {
      padding-left: 0px;
      padding-right: 0px;
      margin-right: 15px;
      color: #cccccc;
    }
    .nav-link:hover{
      border-bottom: 1px solid #AD9379;
      color: #AD9379 !important;
    }
    .active{
      background-color: unset !important;
      color: #AD9379 !important;
      border-bottom: 1px solid #AD9379;
    }

  }

  .media-title{
    font-weight: 300;
    margin-bottom: 0px;
  }
  .empty{
    visibility: hidden;
  }
  .end-message{
    background-color: #f0f0f0 ;
    padding: 0 3em 2em 3em;
    h2{
      padding-top: 20%;
    }
  }
  .hidden-message{
    display: none;
  }

  .middle-end{
    background-color: rgba(45, 44, 44, 0.03);
    padding: 3em 3em 2em 3em;
  }


  .article-content{
    padding: 2em 0 0 0;
  }

  .beige{
    color: #AD9379;
  }
  .view-article{
    width: fit-content;
    font-weight: 400;
    padding-bottom: 2px !important;
    border-bottom: 1px solid #000000;

  }



  a:hover{
    text-decoration: none;
  }

  .tabs-on-media{
    padding: 3em 3em 2em 3em;
    background-color: rgba(45, 44, 44, 0.03);
    min-height: 500px;
  }
  .container-controls-tab-media{
    padding-left: 0;
    padding-right: 0;
    @include media-breakpoint-up(md){
      padding-left: unset;
      padding-right: unset;
    }
  }

  .tab-media-content:hover{
    background-color: #212F43;
    color: #ffffff;
    a{
      color: #ffff;
    }
    .view-article{
      border-bottom: 1px solid #ffffff;
    }
    .first-tabs{
      background-color: #212F43;
    }
    .last-tabs{
      background-color: #212F43;
    }

  }

  .chaflan:before {
    content: "";
    position: absolute;
    top: -5px;
    right: -22px;
    width: 0;
    height: 0;
    border-width: 0 31px 31px;
    border-style: solid;
    border-color: transparent transparent #ffff;
    transform: rotate(45deg);
  }

  .chaflan-small:before {
    content: "";
    position: absolute;
    top: -5px;
    right: -21px;
    width: 0;
    height: 0;
    border-width: 0 31px 31px;
    border-style: solid;
    border-color: transparent transparent #ffff;
    transform: rotate(45deg);
  }

  .chevron{
    cursor: pointer;
  }
  .chevron::before {
    border-style: solid;
    border-width: 0.1em 0.1em 0 0;
    content: '';
    display: inline-block;
    height: 1em;
    left: 0.15em;
    position: relative;
    top: 1.15em;
    transform: rotate(-45deg);
    vertical-align: top;
    width: 1em;
  }
  .gallery-buttons{
    display: none;
    height: 45px;
    @include media-breakpoint-up(md){
      display: block;
      height: 55px;
    }
    .desactive{
      opacity: 0.3;
    }
    .chevron.prev{
      color: #AD9379 ;
      padding-right: 50px;
    }
    .chevron.next{
      padding-left: 50px;
      color: #AD9379 ;
    }
  
    .chevron.prev:before {
      left: 0.25em;
      transform: rotate(-135deg);
  
    }
    .chevron.next:before {
      left: 0;
      transform: rotate(45deg);
  
    }
  }


  .owl-carousel .owl-stage{display: flex;}
  .article-items {
      display: flex;
      flex: 1 0 auto;
      height: 100%;
  }
  .article-box {
      position: relative;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      align-items: stretch;
  }

  .owl-nav{
    display:none;
  }
  .owl-dots{
    display: none;
    @include media-breakpoint-up(md){
      display: none;
    }
    button{
      height: 15px;
      width: 15px;
      margin-right: 4px;
      background-color: #AD9379;
      border-radius: 50%;
      border: 0px;
      padding: 0;
    }
    .active{
      background-color: grey;
    }
  }
  .target-dots{
    .owl-dots{
      padding-top: 0.5rem;
      display: block !important;
      @include media-breakpoint-up(md){
        display: none;
      }
    }
  }

  .title{

    @include media-breakpoint-up(md){
      font-size: 2rem;
      font-weight: 200;
      line-height: 1;
    }
  }
  
  .owl-carousel-media{
    .owl-stage{
      display: flex ;
      text-align: center;
      .owl-item{
        .item{  
          a{
            .backgroundImageMedia{
              height: 300px;
              background-size: contain;
              background-position: center;
              background-repeat: no-repeat;
            }
          }

        }
      }      
    }
  }

  
}

