.publications{
  padding-bottom: 1.5rem;
  @include media-breakpoint-up(md){
    padding-bottom: 4rem;
  }
  .center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }
  img{
    width: 100%;
    height: auto;
  }
  p{
    font-size: 20px;
  }
  .content-col{
    padding: 1em 0 3em 0;
  }

  
}
