$white:    #fff !default;
$gray-100: #f4f4f4 !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #555555 !default;
$gray-700: #495057 !default;
$gray-800: #2d2c2c !default;
$gray-900: #212529 !default;
$black:    #000 !default;

$blue:    #007bff !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     #dc3545 !default;
$orange:  #fd7e14 !default;
$yellow:  #ffc107 !default;
$green:   #28a745 !default;
$teal:    #20c997 !default;
$cyan:    #17a2b8 !default;

$colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$colors: map-merge(
  (
    "blue":       $blue,
    "indigo":     $indigo,
    "purple":     $purple,
    "pink":       $pink,
    "red":        $red,
    "orange":     $orange,
    "yellow":     $yellow,
    "green":      $green,
    "teal":       $teal,
    "cyan":       $cyan,
    "white":      $white,
    "gray":       $gray-600,
    "gray-dark":  $gray-800
  ),
  $colors
);
$primary:      #000 !default;
$secondary:     $gray-600 !default;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-100 !default;
$dark:          $gray-800 !default;

$font-family-sans-serif:      "Open Sans", "Helvetica Neue", Arial, "Noto Sans", sans-serif,"Roboto";
$headings-font-weight: 300;


$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px
) !default;

$border-radius:               0 !default;
$border-radius-lg:            0 !default;
$border-radius-sm:            0 !default;

$input-btn-padding-y-lg:  1.4rem;
$input-btn-padding-x-lg:  2.1rem;

$input-bg: $gray-600;
$input-color: $gray-200;
$input-border-color: $gray-600;
$input-placeholder-color: $gray-500;

$input-group-addon-color:               $primary !default;
$input-group-addon-bg:                  $gray-600 !default;
$input-group-addon-border-color:        $gray-600 !default;


$spacer: 1rem !default;
$spacers: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
  (
    0: 0,
    1: ($spacer * .25),
    2: ($spacer * .5),
    3: $spacer,
    4: ($spacer * 1.5),
    5: ($spacer * 8)
  ),
  $spacers
);
