.tabs{
  background:rgba(196, 196, 196, 0.1);
  padding-top: 3rem;
  padding-bottom: 3rem;
  font-size: 1.2rem;
  .intern-general-selector{
    .nav-item{
      a{
        padding-right: 0;
        margin-right: 0;
       
      }
    }

  }
  .nav-item{
    padding-left: 0;
    padding-right: 0;
    text-align: center;
    @include media-breakpoint-up(md){
     padding-right: 15px;
     text-align: left;
    }
    a {
      border-bottom: 1px solid rgba(196, 196, 196, 0.4);
      opacity: 0.4;
      padding-right: 0px;
      @include media-breakpoint-up(md){
        padding-left: 0px !important;
        padding-right: 7em;
        margin-right: 17px;
       }
    }
    .active{
      background-color: unset !important;
      color: #000000 !important;
      border-bottom: 1px solid #000000;
      opacity: 1;
      padding-left: 0;
      @include media-breakpoint-up(md){
        padding-left: 1rem;
      }
    }

    .nav-link{
      padding-left: 0;
      font-size: 1rem;
      @include media-breakpoint-up(md){
        padding-left: 1rem;
      }
    }

  }
  .pills-number{
    .nav-item{
      a{
        padding-right: 0;
        margin: 0;
      }

    }
  }
  .nav-item:hover{
    a{
      background-color: unset !important;
      color: #000000 !important;
      border-bottom: 1px solid #000000;
      opacity: 1;
    }

  }
  .copy-media{
    font-size: 1rem;
  }
  ol{
    list-style-type: lower-roman;
    font-size: 1rem;
    padding-inline-start: 15px;

    li{
      padding-bottom: 15px;
      padding-left: 25px;
      line-height: 1.4;
      margin-bottom: 1rem;
    }
  }

}
