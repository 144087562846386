.image-banner-var{
  background-repeat: no-repeat;
  background-size: cover;
  p{
    padding-bottom: 0px;
    color: white;
  }
  .image-container{
    padding-top: 7.5rem ;
    @include media-breakpoint-up(md){
      padding-top: 25rem ;
      min-height: unset;
    }
    .content-text{
      background: rgba(33, 47, 67, 0.75);
      color: #ffffff;
      p{
        font-size: 0.9rem;
      }
      // padding: 4rem 0 4rem 0;
      @include media-breakpoint-up(md){
        padding: 4rem 0 4rem 0;
        p{
          font-size: 1.3rem;
        }
      }
      
    }
  }

}
