.image-banner {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 55.952380952381vw;

  background-size: cover;
  background-repeat: no-repeat;

  .banner-mask {

    width: 48%;
    position: absolute;
    bottom: -100px;
    
  }
}

.has-left-gutter {
  margin-left: 16.5%;
  width: 83.5%;
  height: 46.720238095238vw;

  .banner-mask {
    width: 85%;
    position: absolute;
    top: -175px;
    right: -600px;
  }
}
