.logo-emmisary {
  width: 200px;
  // position: absolute;
  // right: 130px;
  // top: 62px;
  margin-top: 62px;
  margin-left: 35px;  
  transition: height 2s;
  float: left;
}
.logo-emmisary-old {
  width: 200px;
  // position: absolute;
  // right: 130px;
  // top: 62px;
  // margin-top: 62px;
  margin-left: 35px;  
  transition: height 2s;
  float: left;
}

.icon-emmisary{
  // position: absolute;
   height: 45px;
  // top:62px;
  padding-left: 30px;
  margin-top: 62px;
  @include media-breakpoint-up(md){
    top:30px;
    right: 55px;
      transition: height 2s;
    
  }
  @include media-breakpoint-up(lg){
    top:62px;
    right: 55px;
  }

}
.icon-emmisary-old{
  // position: absolute;
   height: 45px;
  // top:62px;
  padding-left: 30px;
  // margin-top: 62px;
  @include media-breakpoint-up(md){
    top:30px;
    right: 55px;
      transition: height 2s;
    
  }
  @include media-breakpoint-up(lg){
    top:62px;
    right: 55px;
  }

}

.icon-emmisary-mobile{
  position: fixed;
  height: 30px;
  top: 20px;
  right: 19px;
  z-index: 2;
  @include media-breakpoint-up(md){
    top: 60px;
    right: 55px;
  }
}
.logo-emmisary-mobile{
  position: absolute;
  width: 150px;
  z-index: 2;
  height: 35px;
  top: 20px;
  right: 31%;
  @include media-breakpoint-up(md){
    top: 60px;
    right: 40%;
  }
}

.navbar-brand{
  margin-right: 2rem;

}

.navbar-dark .navbar-toggler{
  border-color: transparent;
}
