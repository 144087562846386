header {
  display: block;
  
  h1{
    font-size: 2.1rem;
    font-weight: 200;
  }
  p{
    font-size: 1.3rem;
    line-height: 1.4;
    font-weight: 200;
  }

  .hero-banner{
    width: 100%;
    position: relative;
    //height: 900px;
    @include media-breakpoint-up(md){
      height: 810px;
    }
    @include media-breakpoint-up(lg){
      height: 900px;
    }
    .overlay{
      position: absolute;
      height: 100%;
      width: 100%;
      background: #212F43;
      opacity: 0.95;
    }

    .hero-container{
      height: 100%;
      padding-bottom: 9em;
    }
    .title{
      color: $white;
      padding-top: 6em;
      font-family: "Roboto";
      h1{
        font-size: 1.8rem;
      }
     @include media-breakpoint-up(md){
      padding-top: 10em;
      h1{
        font-size: 2.1rem;
      }
      @include media-breakpoint-up(lg){
        padding-top: 13em;
        h1{
          font-size: 2.1rem;
        }
      }

     }
    }
    .content{
      color: $white;
      padding-top: 1.2em;
      font-family: "Roboto";
      font-size: 26px;
      line-height: 40px;
    }

  }




}
.title-blue{
  padding-top: 6em;
  font-family: "Roboto";
  h1{
    font-size: 1.8rem;
  }
 @include media-breakpoint-up(md){
  padding-top: 10em;
  h1{
    font-size: 2.1rem;
  }
  @include media-breakpoint-up(lg){
    padding-top: 4em;
    h1{
      font-size: 2.1rem;
    }
  }

 }
}