.text-two-columns {
  background-color: #f6f6f6;


  p{
    color: #212F43;
  }
  font-family: "Roboto";
  padding-bottom: 80px;
  @include media-breakpoint-up(md){
    padding-bottom: 38px;
  }

}


