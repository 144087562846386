.content-menu{
  position: relative;
}


.back-white{
  background-color: white;
}

.m-desktop{
  display: none;
  @include media-breakpoint-up(md){
    display: block;
  }
}
.menu-bc{
  position: fixed;
  transition: top 0.4s;
  top:0px;
  z-index: 9;
  .row{
    height: 146px;
  }
}
.nav{
  padding-left: 15px !important;
}
.blue{
  a{
    color: rgb(32, 44, 64);
    &:hover {
      color: rgb(32, 44, 64);
      opacity: 0.3;
      text-decoration: none;
  
      }
  }
  a:hover{
    border-bottom: 0.3px rgb(32, 44, 64) solid;
    opacity: 0.3;
    padding-bottom: 10px;
  }

}
nav {

  position: absolute;
  font-size: 1.3rem;
  padding-bottom: 22px;
  z-index: 1;
  font-family: Roboto;
  font-weight: 300;
  @include media-breakpoint-up(md){
    padding-top: 37px;
  }
  @include media-breakpoint-up(lg){
    padding-top: 75px;
  }


  a {
    color:  $white;
    //margin-right: 26px;
    display: inline-block;
    width: fit-content;
    @include media-breakpoint-up(md){
      width: fit-content;
      margin-right: 34px;
    }
    text-decoration: none;

    &:hover {
    color: $white;
    opacity: 0.3;
    text-decoration: none;

    }
    &.active{
      border-bottom: 0.3px #202c40 solid;
      opacity: 0.3;
      padding-bottom: 10px;
    
    }
  }
  a:hover{
    border-bottom: 0.3px #fff solid;
    opacity: 0.3;
    padding-bottom: 10px;
  }

}

.navbar-toggler{
  border: 0px;
  padding: 0 0 0 0;
  margin-right: 0;

}
a.navbar-brand{
  margin-right: 0px;
  position: fixed;
  right: 5%;
}

.collapse{
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  left: 0;
}

.brand-container{
  // position: absolute;
  // font-size: 1.3rem;
  // padding-top: 70px;
  // padding-bottom: 22px;
  // z-index: 1;

  position: absolute;
  font-size: 1.3rem;
  padding-top: 70px;
  padding-bottom: 22px;
  z-index: 1;
  right: 0.7%;
}

.no-gutter{
  margin-left: 0px !important;
  margin-right: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.menu-mobile{
  position: absolute;
  width: 100%;
  .dark-blue{
    background-color: rgb(30, 42, 60); 
  }

}


  ////mobile menu
  
  
  #menuToggle
  {
    display: block;
    position: relative;
    top: 29px;
    left: 19px;
    
    z-index: 1;
    
    -webkit-user-select: none;
    user-select: none;
  }
  
  #menuToggle a
  {
    text-decoration: none;
    color: #232323;
    
    transition: color 0.3s ease;
  }
  
  #menuToggle a:hover
  {
    color: tomato;
  }
  
  
  #menuToggle input
  {
    display: block;
    width: 40px;
    height: 32px;
    position: absolute;
    top: -7px;
    left: -5px;
    
    cursor: pointer;
    
    opacity: 0;
    z-index: 2; 
    
    -webkit-touch-callout: none;
  }
  

  #menuToggle span
  {
    display: block;
    width: 24px;
    height: 2px;
    margin-bottom: 4px;
    position: relative;
    
    background: #cdcdcd;
    border-radius: 3px;
    
    z-index: 1;
    
    transform-origin: 4px 0px;
    
    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                opacity 0.55s ease;
  }
  
  #menuToggle span:first-child
  {
    transform-origin: 0% 0%;
  }
  
  #menuToggle span:nth-last-child(2)
  {
    transform-origin: 0% 100%;
  }
  
  
  #menuToggle input:checked ~ span
  {
    opacity: 1;
    transform: rotate(45deg) translate(-2px, -1px);
    background: #232323;
  }
  
  
  #menuToggle input:checked ~ span:nth-last-child(3)
  {
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
  }
  
  
  #menuToggle input:checked ~ span:nth-last-child(2)
  {
    transform: rotate(-45deg) translate(0, -1px);
  }
  
  #menu
  {
    position: fixed;
    width: 100vw;
    height: 100vh;
    margin: 0 0 0 0;
    top: 0;
    left: -1px;
    padding-top: 80px;
    
    background: #ededed;
    list-style-type: none;
    -webkit-font-smoothing: antialiased;
    transform: translate(-100%, 0);
    
    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
  }
  
  #menu li
  {
    padding: 10px 0;
    font-size: 22px;
    border-bottom: 1px solid rgba(0,0,0,0.1);
    margin: 0px 10px 0 10px;
  }

  #menu li:last-child
  {
    border-bottom: 0px solid rgba(0,0,0,0.1);
  }
  
  
  #menuToggle input:checked ~ ul
  {
    transform: none;
  }
  


