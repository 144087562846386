.our-team{
  background-color: rgba(196, 196, 196, 0.1);
  padding: 4em 0 1em 0;
  font-size: 1.4rem;
  @include media-breakpoint-up(lg){
    padding: 4em 0 4em 0;
  }

  .content-our-team{
    padding-top: 4em;
  }

  .team-content{
    position: relative;
    cursor: pointer;
    border-bottom:1px solid rgba(0,0,0,0.1);
  }
  
  .team-content:last-child(){
    @include media-breakpoint-up(md){ 
      border-bottom:0px solid rgba(0,0,0,0.1);
    }
  }
  .team-content:first-child(){
    padding-top: 1rem;
    @include media-breakpoint-up(md){
      padding-top: 0;
    }
  }

  .last-member{
    border-bottom: 0px solid rgba(0,0,0,0.1);
    @include media-breakpoint-up(md){
      border-bottom:0px solid rgba(0,0,0,0.1);
    }

  }
  .team-content:hover{
    opacity: 0.6;
  
  }
  .close-button{
    cursor: pointer;
    font-size: 25px;
    //background-image: url("../images/close.svg");
    img{
      height: 25px;
      width: 25px;
    }
  }
  .close-button-content{
    text-align: end;
  }
  .full-bio{
    width: fit-content;
    font-weight: 400;
    padding-bottom: 2px !important;
    border-bottom: 1px solid #000000;
    margin-bottom: 15px;
    text-decoration: none;
  }


  .title-our-team:before, .title-our-team:after {
      border-right: 2px solid;
      content: '';
      display: block;
      height: 8px;
      margin-top: -6px;
      position: absolute;
      -moz-transform: rotate(135deg);
      -o-transform: rotate(135deg);
      -webkit-transform: rotate(135deg);
      transform: rotate(135deg);
      right: 10px;
      top: 28%;
      width: 0;
  }
  .title-our-team:after {
      margin-top: -1px;
      -moz-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
  }

  .opacity-our-team{
    opacity: 0.3;
  }

  .full-screen-team{    
    padding-top: 48px;
    background-color: #eaeaea;
    height: 100vh;
    font-weight: 300;
    font-size: 20px;
    top: 0;
    left: 110%;
    position: fixed;
    transition-property: left;
    transition-duration: 1s;
    p{
      color: #263345;
    }
  }
  .text-align-right{
    text-align: right;
  }
  .our-team-active{
    -webkit-backface-visibility: hidden;
    height: 100%;
    left: 0;
    outline: none;
    -webkit-tap-highlight-color: transparent;
    top: 0;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    transform: translateZ(0);
    width: 100%;
    z-index: 99992;
    display: block !important;
    overflow-y: auto;
    left: 0;
    overflow-x: hidden

  }

  .variabelMargin{
    @include media-breakpoint-up(md){
      max-width: 720px;
    }
    @include media-breakpoint-up(lg){
      max-width: 960px;
    }
    @include media-breakpoint-up(xl){
      max-width: 1140px
    }

  }

}
