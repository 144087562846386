.image-banner-text{
  font-family: "Roboto";
  background-size: cover;
  background-repeat: no-repeat;
  height: 1027px;
  font-weight: 300;
  font-size: 1.5rem;
  color: $white;
  height: 350px;
  padding-top: 6rem;
  @include media-breakpoint-up(md) {
      padding-top: 123px;
  }
  @include media-breakpoint-up(lg) {
      //padding-top: 9rem;
      padding-top: 12rem;
  }
  p{
    font-size: 1rem;
    @include media-breakpoint-up(md) {
      font-size: 1.3rem;
    }
  }
  @include media-breakpoint-up(md) {
    height: 462px;
  }
  @include media-breakpoint-up(lg){
    height: 750px;
  }
  .content-text{
    width: unset;
    position: absolute;
    @include media-breakpoint-up(md) {
      width: 80%;
    }
  }
  .banner-text-mobile{
    padding-top: 20%;
  }
}




